<template>
  <div ref="modal_overlay" class="modal_overlay">
    <div class="daily_quiz_reg_modal_container">
      <div class="daily_quiz_reg_modal close_btn_area">
        <button type="button" class="close_btn" @click="$emit('closeDailyQuizRegModal')">×</button>
      </div>

      <div class="first_mode_select_step" v-if="mode === '' || step === 1">
        <p class="step_title">학습 모드를 선택하세요.</p>
        <p class="step_desc">본인의 학습 방식에 따라 모드를 선택해주세요!</p>
        <div class="mode_area">
          <div class="mode_select_area">
            <button type="button" :class="'mode_select' + (mode === 'S' ? ' selected' : '')" @click="selectMode('S')"/>
            <p class="mode_desc">선택 모드</p>
          </div>
          <div class="mode_random_area">
            <button type="button" :class="'mode_random' + (mode === 'R' ? ' selected' : '')" @click="selectMode('R')"/>
            <p class="mode_desc">랜덤 모드</p>
          </div>
        </div>

        <div class="next_btn_area">
          <button type="button" class="disable_btn" v-if="mode === ''">다음</button>
          <button type="button" class="next_btn" v-else @click="modeSelectComplete">다음</button>
        </div>
      </div>

      <DailyQuizRandomModRegModal v-if="mode === 'random'" @nextStep="nextStep" :step="step"/>
      <DailyQuizSelectModeRegModal v-else-if="mode === 'select'" @nextStep="nextStep" :step="step"/>

      <div class="complete_step" v-if="(mode === 'random' && step === 4) || (mode === 'select' && step === 5)">
        <p class="step_title">성공적으로 등록되었습니다.</p>
        <p class="step_desc">등록된 일일 퀴즈는<br/>매일 학습 관리에서 확인할 수 있습니다.</p>

        <div class="complete_img_area">
          <div class="complete_img"></div>
        </div>

        <div class="confirm_btn_area">
          <button type="button" class="confirm_btn" @click="completeReload">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import RadialProgress from "vue3-radial-progress";
import DailyQuizRandomModRegModal from "@/components/modal/quiz/sub/DailyQuizRandomModRegModal";
import DailyQuizSelectModeRegModal from "@/components/modal/quiz/sub/DailyQuizSelectModeRegModal";

export default {
  name: 'DailyQuizRegModal',
  components: {DailyQuizSelectModeRegModal, DailyQuizRandomModRegModal, RadialProgress},
  data:() => ({
    mode: '',
    step: 1,
  }),
  created() {
  },
  methods: {
    selectMode(mode){
      this.mode = mode;
    },
    modeSelectComplete(){
      if(this.mode === 'R'){
        this.mode = 'random'
      } else if(this.mode === 'S'){
        this.mode = 'select';
      }

      this.nextStep();
    },
    nextStep(){
      this.step += 1;
    },
    completeReload(){
      location.reload();
    }
  }
}
</script>

<style scoped>
  @import "@/assets/modal/manage/DailyQuizRegModal.css";
</style>