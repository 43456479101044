<template>
  <div ref="modal_overlay" class="modal_overlay">
    <template v-for="(dailyQuizItem, index) in dailyQuizItemList">
      <div :class="'modal_daily_quiz_container' + (index == 0 ? ' on' : '')" :id="'step' + index" >
        <div class="daily_quiz_modal close_btn_area">
          <button type="button" class="close_btn" @click="$emit('closeDailyQuizModal')">×</button>
        </div>
        <input type="hidden" name="quiz_id" :value="dailyQuizItem.problemId"/>
          <div class="daily_quiz_area">

            <div v-if="hintKeywordList != null && hintKeywordList.length > 0">
              <button class="hint_btn" @click="showHint()"></button>
              <div class="hint_area">
                <template v-for="hint in dailyQuizItem.hintKeywordList">
                  <span class="hint_keyword">{{ hint }}</span>
                </template>
              </div>
            </div>
            <div class="daily_quiz_title_area">
              <span class="daily_quiz_title">{{ dailyQuizItem.title }}</span>
            </div>
            <div class="daily_quiz_problem_area">
              <span class="daily_quiz_problem">{{ dailyQuizItem.content }}</span>
            </div>
            <div class="daily_quiz_input_correct_area">
              <textarea class="daily_quiz_input" name="quiz_answer" value="" placeholder="자신의 생각을 답변해주세요."/>
            </div>
            <div class="daily_quiz_btn_area">
              <button class="btn prev_step" v-if="index > 0" @click="prevStep(index - 1)">이전</button>
              <button class="btn next_step" v-if="index != (dailyQuizItemList.length - 1)" @click="nextStep(index + 1)">다음 ({{curStep}} / {{totalStep}})</button>
              <button class="btn submit" v-if="index == (dailyQuizItemList.length - 1)" @click="submit(dailyQuizId)">제출 ({{curStep}} / {{totalStep}})</button>
            </div>
          </div>
      </div>
    </template>
  </div>
</template>

<script>
import apiClient from "@/plugins/apiClient";
import {isNullOrEmptyStr} from "@/utils/validationUtils";
import {toastFail, toastSuccess} from "@/utils/toastUtils";

export default {
  props: ['dailyQuizId'],
  name: 'DailyQuizModal',
  data: () => ({
    title: '',
    content: '',
    hintKeywordList: [],
    dailyQuizItemList: {},
    curStep : 1,
    totalStep: 0
  }),
  created() {
    this.title= '';
    this.content= '';
    this.answerList= [];
    this.likeFlag = 'N';
    this.getDailyQuizItemList(this.dailyQuizId);
  },
  methods: {
    getDailyQuizItemList(dailyQuizId) {
      apiClient.get('/api/daily-quiz/' + dailyQuizId).then(data => {
        this.dailyQuizItemList = data.data.resData;
        this.totalStep = this.dailyQuizItemList.length;
      })
    },
    showHint() {
      if ($(".hint_area").hasClass('on')) {
        $(".hint_area").removeClass('on');
        $(".hint_area").addClass('off');
      } else {
        $(".hint_area").removeClass('off');
        $(".hint_area").addClass('on');
      }
    },
    nextStep(index){
      $(".hint_area").removeClass('on');
      $(".modal_daily_quiz_container").removeClass("on");
      $(".modal_daily_quiz_container").removeClass("off");
      $("#step" + index).addClass("on");

      this.curStep += 1;
      $(".step.id" + this.curStep).addClass("active");
      let calcProgressWidth = ((this.curStep - 1) / (this.dailyQuizItemList.length - 1)) * 100 + '%';

      $(".indicator").css("width", calcProgressWidth);
    },
    prevStep(index){
      $(".hint_area").removeClass('on');
      $(".modal_daily_quiz_container").removeClass("on");
      $(".modal_daily_quiz_container").removeClass("off");
      $("#step" + index).addClass("on");

      $(".step.id" + this.curStep).removeClass("active");
      this.curStep -= 1;
      let calcProgressWidth = ((this.curStep - 1) / (this.dailyQuizItemList.length - 1)) * 100 + '%';

      $(".indicator").css("width", calcProgressWidth);
    },
    submit(dailyQuizId){
      let params = {};
      let dataMapList = [];

      $(".modal_daily_quiz_container").each(function(){
        if(isNullOrEmptyStr($(this).find(".daily_quiz_input").val())){
          toastFail("답변하지 않은 퀴즈가 있습니다.");
          dataMapList = [];
          return false;
        }
        let dataMap = {};
        dataMap.quizId= $(this).find("input[name=quiz_id]").val();
        dataMap.quizAnswer= $(this).find(".daily_quiz_input").val();
        dataMapList.push(dataMap);
      });

      params.dailyQuizSubmitItems = dataMapList;
      params.dailyQuizId = dailyQuizId;

      if(dataMapList != null && dataMapList.length > 0){
        apiClient.post('/api/daily-quiz/answer/submit', params).then(data => {
          if(data.data.resData > 0) {
            toastSuccess('제출 성공하였습니다.');
            this.$emit('closeDailyQuizModal');
          }else{
            toastFail('제출 실패하였습니다.');
          }
        });
      }
    }
  }
}
</script>
<style scoped>
  @import "@/assets/common/animation.scss";
  @import "@/assets/modal/quiz/DailyQuizModal.scss";
</style>