<template>
  <div class="home_area">
    <div class="main_description_area">
      <div class="left_area"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration">
        <div class="background_img_area">
          <img class="background_img" src="@/resource/img/bg.jpg"/>
        </div>
        <div class="represent_txt_area">
          <h1 class="represent_service_txt">
            쉽고 빠르게 암기할 수 있습니다.
            <br/>
            <span style="font-weight: bold; color: #0a8dff;">개념 & 용어</span>를 매일 조금씩 퀴즈로 학습하세요!
            <br/>

          </h1>
          <h2 class="sub_txt">
            암기하고 싶은 용어를 쉽게 등록할 수 있습니다. <br class="sub_txt_br1"/>일일 퀴즈를 이용해 효율적으로 학습해 보세요.
          </h2>
          <button class="start_btn not_login" @click="openRegisterModal" v-if="!login">가입하기</button>
          <button class="start_btn login" @click="openWriteModal" v-else>퀴즈 등록</button>
        </div>
      </div>
    </div>

    <div class="feature_section_area_1">
      <div class="left_area">
        <p class="large_txt"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
          나만의
          <br/>
          용어 퀴즈 제작
        </p>
        <p class="sub_txt"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
          암기하고 싶거나 다른 사람들의 생각이
          <br/>
          궁금한 용어를 자유롭게 등록해보세요.
        </p>
      </div>
      <div class="right_area">
        <div class="sample_img"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
        </div>
      </div>
    </div>

    <div class="feature_section_area_2">
      <div class="left_area">
        <div class="sample_img"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" />
      </div>
      <div class="right_area">
        <p class="large_txt"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
          다른 사람과 생각을
          <br/>
          함께 나눠보세요!
        </p>
        <p class="sub_txt"   data-sal="slide-up" :data-sal-duration="scrollAnimationDuration">
          자신이 미처 생각하지 못했던 새로운
          <br/>
          아이디어와 해결책을 발견할 수 있을 거예요.
        </p>
      </div>
    </div>

    <div class="feature_section_area_3">
      <div class="left_area">
        <p class="large_txt"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
          하루 5분 투자로
          <br/>
          용어 완전 정복!
        </p>
        <p class="sub_txt"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
          매일 조금씩 학습하여
          <br/>
          지식을 탄탄히 쌓아보세요.
        </p>
      </div>
      <div class="right_area">
        <div class="sample_img"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" />
      </div>
    </div>

    <div class="feature_section_area_4">
      <div class="left_area">
        <div class="sample_img"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" />
      </div>
      <div class="right_area">
        <p class="large_txt"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
          일일 퀴즈 학습 기록을
          <br/>
          점검하세요!
        </p>
        <p class="sub_txt"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
          학습 기록을 체크하여 필요한
          <br/>
          부분을 보완할 수 있습니다.
        </p>
      </div>
    </div>
  </div>

  <Footer/>
  <RegisterModal @closeRegisterModal=closeRegisterModal @openLoginModal=openLoginModal :registerModalOn="registerModalOn" v-if="registerModalOn"/>
  <WriteModal @closeWriteModal=closeWriteModal v-if="writeModalOn"/>
</template>
<script>
import Loader from "@/components/common/Loader";
import RegisterModal from "@/components/modal/regist/RegisterModal";
import WriteModal from "@/components/modal/write/WriteModal";
import sal from "sal.js";
import Footer from "@/components/layout/Footer.vue";

export default{
  name: 'Home',
  components: {Footer, WriteModal, RegisterModal, Loader},
  data : () => ({
    login : false,
    registerModalOn : false,
    writeModalOn: false,
    scrollAnimationDuration: 300
  }),
  computed:{
    isLoginCheck(){
      this.login = this.$store.state.auth.isLogin;
      return this.$store.state.auth.isLogin;
    }
  },
  watch:{
    isLoginCheck(){
      this.login = !!this.$store.state.auth.isLogin;
    }
  },
  mounted() {
    sal();
  },
  methods: {
    closeRegisterModal: function (){
      this.registerModalOn = false;
    },
    openRegisterModal: function (){
      this.registerModalOn = true;
    },
    openLoginModal: function (){
      this.loginModalOn = true;
    },
    openWriteModal(){
      this.writeModalOn = true;
    },
    closeWriteModal: function (){
      this.writeModalOn = false;
    },
  }
}
</script>

<style scoped>
@import "@/assets/common/animation.css";
@import "@/assets/main/home/Home.css";
</style>