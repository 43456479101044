<template>
  <div class="mode_select_step" v-if="step === 2">
    <p class="step_title">일일 퀴즈 횟수와 목표 수행 수를 입력해주세요.</p>
    <div class="cnt_input_area">
      <div class="daily_cnt_area">
        <p class="step_desc">일일 퀴즈 횟수</p>
        <div class="circle_chart">
          <RadialProgress
              :diameter="130"
              :completed-steps="dailyCnt"
              :total-steps="dailyCntLimit"
              :startColor="'#df8d8d'"
              :stopColor="'#df8d8d'"
              :animateSpeed="400"
              :innerStrokeColor="'#D0F0E5'"
              :innerStrokeWidth="12"
              :strokeWidth="12"
              :fps="100">
            <div class="circle_chart_info">
              <span class="daily_cnt_label">{{ dailyCntLimit }} / </span>
              <input type="number" name="daily_cnt" id="daily_cnt" @focusin="focusSelf($event)" @input="handleDailyCntChange($event)" :value="dailyCnt"/>
            </div>
          </RadialProgress>
        </div>
      </div>
      <div class="target_cnt_area">
        <p class="step_desc">목표 수행 수</p>
        <div class="circle_chart">
          <RadialProgress
              :diameter="130"
              :completed-steps="targetCnt"
              :total-steps="30"
              :startColor="'#EB85FF'"
              :stopColor="'#EB85FF'"
              :animateSpeed="400"
              :innerStrokeColor="'#D0F0E5'"
              :innerStrokeWidth="12"
              :strokeWidth="12"
              :fps="100">
            <div class="circle_chart_info">
              <span class="target_cnt_label">30 / </span>
              <input type="number" name="target_cnt" id="target_cnt" @focusin="focusSelf($event)" @input="handleTargetCntChange($event)" :value="targetCnt"/>
            </div>
          </RadialProgress>
        </div>
      </div>
    </div>

    <div class="next_btn_area">
      <button type="button" class="next_btn" v-if="dailyCnt !== 0 && targetCnt !== 0" @click="$emit('nextStep')">다음</button>
      <button type="button" class="disable_btn" v-else>다음</button>
    </div>
  </div>

  <div class="third_mode_random_step" v-if="step === 3">
    <p class="step_title">일일 퀴즈 이름을 입력해 주세요.</p>
    <input type="text" name="daily_quiz_name" id="daily_quiz_name" @input="handleDailyQuizName"/>

    <div class="next_btn_area">
      <button type="button" class="disable_btn" v-if="dailyQuizName === ''">다음</button>
      <button type="button" class="next_btn" v-else @click="saveRandomDailyQuiz">다음</button>
    </div>
  </div>
</template>

<script>
import {isNullOrEmptyStr, isNullOrZero} from "@/utils/validationUtils";
import {toastFail} from "@/utils/toastUtils";
import apiClient from "@/plugins/apiClient";
import RadialProgress from "vue3-radial-progress";

export default {
  name: "DailyQuizRandomModRegModal",
  components: {RadialProgress},
  props: ['step'],
  data: () => ({
    dailyCnt: 0,
    dailyCntLimit: 0,
    targetCnt: 0,
    likedQuizCnt: 0,
    dailyQuizName: ''
  }),
  created() {
    this.getLikedQuizCnt();
  },
  methods: {
    getLikedQuizCnt(){
      apiClient.get('/api/daily-quiz/liked/count').then(data => {
        let likedQuizCnt = data.data.resData;

        if(likedQuizCnt < 30){
          this.dailyCntLimit = likedQuizCnt;
        } else{
          this.dailyCntLimit = 30;
        }
      })
    },
    focusSelf(event){
      $('#' + event.target.id).select();
    },
    handleDailyCntChange(event){
      let val = event.target.value;

      if(isNaN(val)){
        this.dailyCnt = 0
      }

      if(val > this.dailyCntLimit){
        this.dailyCnt = this.dailyCntLimit;
      }else if(val < 0){
        this.dailyCnt = 0;
      }else{
        this.dailyCnt = event.target.value;
      }
    },
    handleTargetCntChange(event){
      let val = event.target.value;

      if(isNaN(val)){
        this.targetCnt = 0
      }

      if(val > 30){
        this.targetCnt = 30;
      }else if(val < 0){
        this.targetCnt = 0;
      }else{
        this.targetCnt = event.target.value;
      }
    },
    handleDailyQuizName(event){
      this.dailyQuizName = event.target.value;
    },
    saveRandomDailyQuiz(){
      if(isNullOrZero(this.dailyCnt)){
        toastFail("일일 퀴즈 횟수가 존재하지 않습니다. 다시 등록해주세요.");
        return false;
      }

      if(isNullOrZero(this.targetCnt)){
        toastFail("목표 수행 수가 존재하지 않습니다. 다시 등록해주세요.");
        return false;
      }

      if(isNullOrEmptyStr(this.dailyQuizName)){
        toastFail("일일 퀴즈 이름을 입력해주세요.");
        return false;
      }

      let params = {};
      params.mode = 'R';
      params.dailyCnt = this.dailyCnt;
      params.targetCnt = this.targetCnt;
      params.dailyQuizName = this.dailyQuizName;
      params.quizList = [];

      apiClient.post('/api/daily-quiz', params).then(data => {
        this.$emit('nextStep');
        this.emitter.emit("getMyDailyQuizList");
      });
    },
  }
}
</script>

<style scoped>
@import "@/assets/modal/manage/DailyQuizRegModal.css";
</style>