<template>
  <div class="third_mode_select_step" v-if="step === 2">
    <p class="step_title">내가 좋아요 한 퀴즈 중 <br/>일일 퀴즈에 등록할 퀴즈를 선택해 주세요.</p>

    <ul class="quiz_item_list">
      <template v-for="(myQuizItem, index) in myQuizList" >
        <div :class="this.selectQuizList.includes(myQuizItem.quizId)
                            ? 'quiz_item' + ' id' + myQuizItem.quizId + ' add'
                            : 'quiz_item' + ' id' + myQuizItem.quizId"
             @click="addDailyQuizList(myQuizItem.quizId, $event)">
          <p class="category">{{ myQuizItem.categoryName }}</p>
          <span class="title">{{ myQuizItem.title }}</span>

          <div class="add_icon_area">
            <div class="add_icon"></div>
          </div>
        </div>
      </template>
    </ul>

    <div class="next_btn_area">
      <button type="button" class="disable_btn" v-if="selectQuizList.length === 0">다음 (0 / {{dailyCntLimit}})</button>
      <button type="button" class="next_btn" v-else @click="setDailyQuiz">다음 ({{selectQuizList.length}} / {{dailyCntLimit}})</button>
    </div>
  </div>

  <div class="mode_select_step" v-if="step === 3">
    <p class="step_title">목표 수행 수를 입력해주세요.</p>
    <div class="cnt_input_area">
      <div class="target_cnt_area">
        <p class="step_desc">목표 수행 수</p>
        <div class="circle_chart">
          <RadialProgress
              :diameter="130"
              :completed-steps="targetCnt"
              :total-steps="30"
              :startColor="'#EB85FF'"
              :stopColor="'#EB85FF'"
              :animateSpeed="400"
              :innerStrokeColor="'#D0F0E5'"
              :innerStrokeWidth="12"
              :strokeWidth="12"
              :fps="100">
            <div class="circle_chart_info">
              <span class="target_cnt_label">30 / </span>
              <input type="number" name="target_cnt" id="target_cnt" @focusin="focusSelf($event)" @input="handleTargetCntChange($event)" :value="targetCnt"/>
            </div>
          </RadialProgress>
        </div>
      </div>
    </div>

    <div class="next_btn_area">
      <button type="button" class="next_btn" v-if="targetCnt !== 0" @click="$emit('nextStep');">다음</button>
      <button type="button" class="disable_btn" v-else>다음</button>
    </div>
  </div>

  <div class="third_mode_random_step" v-if="step === 4">
    <p class="step_title">일일 퀴즈 이름을 입력해 주세요.</p>
    <input type="text" name="daily_quiz_name" id="daily_quiz_name" @input="handleDailyQuizName"/>

    <div class="next_btn_area">
      <button type="button" class="disable_btn" v-if="dailyQuizName === ''">다음</button>
      <button type="button" class="next_btn" v-else @click="saveSelectDailyQuiz">다음</button>
    </div>
  </div>
</template>

<script>
import {isNullOrEmptyList, isNullOrEmptyStr, isNullOrZero} from "@/utils/validationUtils";
import {toastFail} from "@/utils/toastUtils";
import apiClient from "@/plugins/apiClient";
import RadialProgress from "vue3-radial-progress";

export default {
  name: "DailyQuizSelectModeRegModal",
  components: {RadialProgress},
  props: ['step'],
  data: () => ({
    dailyCnt: 0,
    dailyCntLimit: 20,
    targetCnt: 0,
    dailyQuizName: '',
    myQuizList : [],
    selectQuizList: [],
    selectCount: 0,
  }),
  created() {
    this.getLikedQuizList();
  },
  methods: {
    focusSelf(event){
      $('#' + event.target.id).select();
    },
    addDailyQuizList(quizId, event){
      if($(event.target).hasClass('add')){
        $(event.target).removeClass('add');

        this.selectQuizList = this.selectQuizList.filter((ele) => {
          return ele != quizId;
        });

        this.selectCount-=1;

        return false;
      }

      if(this.selectQuizList.includes(quizId)){
        return false;
      }
      if(this.selectQuizList.length >= this.dailyCntLimit){
        toastFail("최대 " + this.dailyCntLimit + "개까지만 등록 가능합니다.");
        return false;
      }

      this.selectQuizList.push(quizId);
      this.selectCount+=1;
      $(event.target).addClass('add');
    },
    getLikedQuizList() {
      apiClient.get('/api/liked-quizzes').then(data => {
        this.myQuizList = data.data.resData.likedQuizItemList;
      });
    },
    setDailyQuiz(){
      this.dailyCnt = this.selectQuizList.length;
      this.$emit('nextStep');
    },
    handleTargetCntChange(event){
      let val = event.target.value;

      if(isNaN(val)){
        this.targetCnt = 0
      }

      if(val > 30){
        this.targetCnt = 30;
      }else if(val < 0){
        this.targetCnt = 0;
      }else{
        this.targetCnt = event.target.value;
      }
    },
    handleDailyQuizName(event){
      this.dailyQuizName = event.target.value;
    },
    saveRandomDailyQuiz(){
      if(isNullOrZero(this.dailyCnt)){
        toastFail("일일 퀴즈 횟수가 존재하지 않습니다. 다시 등록해주세요.");
        return false;
      }

      if(isNullOrZero(this.targetCnt)){
        toastFail("목표 수행 수가 존재하지 않습니다. 다시 등록해주세요.");
        return false;
      }

      if(isNullOrEmptyStr(this.dailyQuizName)){
        toastFail("일일 퀴즈 이름을 입력해주세요.");
        return false;
      }

      let params = {};
      params.mode = 'R';
      params.dailyCnt = this.dailyCnt;
      params.targetCnt = this.targetCnt;
      params.dailyQuizName = this.dailyQuizName;
      params.quizList = [];

      apiClient.post('/api/daily-quiz', params).then(data => {
        this.$emit('nextStep');
        this.emitter.emit("getMyDailyQuizList");
      });
    },
    saveSelectDailyQuiz(){
      if(isNullOrZero(this.dailyCnt)){
        toastFail("일일 퀴즈 횟수가 존재하지 않습니다. 다시 등록해주세요.");
        return false;
      }

      if(isNullOrZero(this.targetCnt)){
        toastFail("목표 수행 수가 존재하지 않습니다. 다시 등록해주세요.");
        return false;
      }


      if(isNullOrEmptyList(this.selectQuizList)){
        toastFail("선택된 일일 퀴즈 리스트가 존재하지 않습니다. 다시 등록해주세요.");
        return false;
      }

      if(isNullOrEmptyStr(this.dailyQuizName)){
        toastFail("일일 퀴즈 이름을 입력해주세요.");
        return false;
      }

      let params = {};
      params.mode = 'S';
      params.dailyCnt = this.dailyCnt;
      params.targetCnt = this.targetCnt;
      params.dailyQuizName = this.dailyQuizName;
      params.quizList = this.selectQuizList;

      apiClient.post('/api/daily-quiz', params).then(data => {
        this.$emit('nextStep');
      });
    },
  }
}
</script>

<style scoped>
@import "@/assets/modal/manage/DailyQuizRegModal.css";
</style>